import React from 'react';
import './InvestmentG.css';
import aiMarketplace from '../assets/images/market-prev.jpg';
import bumpupsPrev from '../assets/images/bump-prev.jpg';
import skoolPrev from '../assets/images/skoo-prev.jpg';

function InvestmentG() {
  const investments = [
    {
      name: 'AI Marketplace',
      description: 'Pre-built Services',
      displayLink: 'webcafesoftware.com',
      actualLink: 'https://webcafesoftware.com',
      image: aiMarketplace,
      isFeatured: false
    },
    {
      name: 'Bumpups',
      description: 'For Creators, Editors & Podcasters',
      displayLink: 'bumpups.com',
      actualLink: 'https://bumpups.com',
      image: bumpupsPrev,
      isFeatured: true
    },
    {
      name: 'Skool',
      description: 'AI For Business',
      displayLink: 'skool.com',
      actualLink: 'https://www.skool.com/ai-for-your-business/about',
      image: skoolPrev,
      isFeatured: false
    }
  ];

  return (
    <div className="invest-gallery">
      {investments.map((investment, index) => (
        <div
          key={index}
          className={`invest-card ${investment.isFeatured ? 'featured-card' : ''}`}
          onClick={() => window.open(investment.actualLink, '_blank')}
        >
          <img src={investment.image} alt={investment.name} />
          <div className="invest-info">
            <h2>{investment.name}</h2>
            <p>{investment.description}</p>
            <p className="invest-price">{investment.displayLink}</p>
            <button className="invest-button">VIEW INVESTMENT</button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default InvestmentG;
